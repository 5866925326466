import React, { useState, useEffect, ReactNode } from 'react'
import axios from 'axios'

interface IGeoContext {
  loading: boolean
  isUS?: boolean
  brand?: string
  url?: string
}

export type GeoContextValue = undefined | IGeoContext

export const GeoContext = React.createContext<GeoContextValue>(undefined)

interface GeoStateProps {
  children: ReactNode
}

const GeoState = ({ children }: GeoStateProps) => {
  const [loading, setLoading] = useState(true)
  const [isUS, setIsUS] = useState<boolean>()
  const [brand, setBrand] = useState<string>()
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    axios
      .get(
        'https://5jo58ujmw0.execute-api.eu-central-1.amazonaws.com/production/location'
      )
      .then(response => {
        setIsUS(
          ['US', 'CA'].indexOf(response.data.location.country.iso_code) !== -1
        )
      })
      .catch(() => {
        setIsUS(true)
      })
      .then(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (isUS !== null) {
      setBrand(isUS ? 'EM' : 'Merck')
      setUrl(isUS ? 'emdgroup.com' : 'merckgroup.com')
    }
  }, [isUS])

  return (
    <GeoContext.Provider value={{ loading, isUS, brand, url }}>
      {children}
    </GeoContext.Provider>
  )
}

export default GeoState
