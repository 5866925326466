import React, { ReactNode } from 'react'
import GeoState from './state/geo'

interface GlobalContextProviderProps {
  element: ReactNode;
}

const GlobalContextProvider = ({ element } : GlobalContextProviderProps) => {
  return <GeoState>{element}</GeoState>
}

GlobalContextProvider.propTypes = {
  element: React.Children,
}

export default GlobalContextProvider
